import React, { ComponentProps } from 'react';
import { ShimmeringLoaderBar } from '@kyruus/loaders-and-spinners';

import { MarketableLocation } from 'Src/types';
import { getHeroImage } from 'Src/utils/location';
import { ImageLoadingContainer } from '../styles';
import { HeroImage } from './styles';

const DEFAULT_IMAGE = 'https://cdn.kyruus.com/img/location-hero-default.jpg';

interface BaseImageProps {
  loading: boolean;
  marketableLocation?: MarketableLocation;
}

type ImageProps = BaseImageProps & ComponentProps<'div'>;

export const Image = ({
  loading,
  marketableLocation,
  ...props
}: ImageProps) => {
  if (loading) {
    return (
      <ImageLoadingContainer
        data-testid="Loading"
        role="presentation"
        {...props}
      >
        <ShimmeringLoaderBar />
      </ImageLoadingContainer>
    );
  }

  if (!marketableLocation) {
    return null;
  }

  return (
    <div data-testid="ImageContainer" {...props}>
      <HeroImage
        data-testid="HeroImage"
        imageUrl={getHeroImage(marketableLocation.media) || DEFAULT_IMAGE}
      />
    </div>
  );
};
