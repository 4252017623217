import { StateMachine } from 'xstate';
import {
  LARGE_IMAGE_SIZE,
  MEDIUM_IMAGE_SIZE,
  SMALL_IMAGE_SIZE,
  MAP_PROVIDERS
} from '../constants';

export const STATES = {
  initializing: '@states/INITIALIZING',
  loading: '@states/LOADING',
  idle: '@states/IDLE',
  error: '@states/ERROR'
} as const;

export const EVENTS = {
  fetchMapSuccess: 'done.invoke.fetchMap',
  fetchMapError: 'error.platform.fetchMap'
} as const;

export type MapUrls = {
  [SMALL_IMAGE_SIZE]: string;
  [MEDIUM_IMAGE_SIZE]: string;
  [LARGE_IMAGE_SIZE]: string;
};

export type MapData = {
  data: MapUrls;
};

export type MapStateMachine = StateMachine<
  MapContext,
  MapStateSchema,
  MapEvent
>;

export interface MapContext {
  error?: unknown;
  mapUrls?: MapUrls;
  locations: string[];
  zoom?: number;
  provider?: (typeof MAP_PROVIDERS)[keyof typeof MAP_PROVIDERS];
}

export interface MapStateSchema {
  states: {
    [STATES.initializing]: Record<string, unknown>;
    [STATES.idle]: Record<string, unknown>;
    [STATES.loading]: Record<string, unknown>;
    [STATES.error]: Record<string, unknown>;
  };
}

export type MapEvent =
  | ({
      type: typeof EVENTS.fetchMapSuccess;
    } & MapData)
  | { type: typeof EVENTS.fetchMapError; error: unknown };
