import React from 'react';
import { useMachine } from '@xstate/react';
import { injectIntl } from 'react-intl';
import { StaticGmap } from '@kyruus/gmap';
import { AwsMap } from '@kyruus/aws-map';
import { WrappedComponentProps } from '@kyruus/intl';
import { PMCConfig } from '@kyruus/types';

import { getConfigProperty } from 'Common/config';
import { AppSettings, MarketableLocation } from 'Src/types';
import { ContentSection } from '../styles';
import messages from '../messages';
import { Container } from './styles';
import { createMapMachine, STATES } from './machine';
import { locationToMapAddress } from './utils';
import {
  SMALL_IMAGE_SIZE,
  MEDIUM_IMAGE_SIZE,
  LARGE_IMAGE_SIZE,
  SMALL_MAP_HEIGHT,
  MEDIUM_MAP_HEIGHT,
  LARGE_MAP_HEIGHT,
  MAP_PROVIDERS
} from './constants';

interface MapProps extends WrappedComponentProps {
  appSettings: AppSettings;
  config: PMCConfig;
  marketableLocation: MarketableLocation;
}

export const Map = injectIntl(
  ({ marketableLocation, appSettings, config, intl }: MapProps) => {
    const { _entity_id: locationId, coordinates } = marketableLocation || {};
    const mapProvider = getConfigProperty(
      config,
      'maps_config.location_service_provider'
    );

    const [state] = useMachine(
      createMapMachine({
        provider: mapProvider,
        locations: [locationId]
      })
    );

    if (!coordinates?.lat || !coordinates.lng) {
      return null;
    }

    if (mapProvider === MAP_PROVIDERS.AWS) {
      return (
        <ContentSection>
          <Container data-testid="LocationAwsMap">
            <AwsMap
              awsMapConfig={{
                identityPoolId:
                  appSettings.AWS_MAPS_CREDENTIALS.mapIdentityPoolId,
                mapName: appSettings.AWS_MAPS_CREDENTIALS.mapName,
                region: appSettings.AWS_MAPS_CREDENTIALS.mapRegion
              }}
              locations={[marketableLocation]}
            />
          </Container>
        </ContentSection>
      );
    }

    const { mapUrls } = state.context;
    if (
      state.matches(STATES.error) ||
      state.matches(STATES.loading) ||
      state.matches(STATES.initializing) ||
      !mapUrls
    ) {
      return null;
    }

    return (
      <ContentSection>
        <Container data-testid="LocationStaticGmap">
          <StaticGmap
            smallImage={mapUrls[SMALL_IMAGE_SIZE]}
            smallHeight={SMALL_MAP_HEIGHT}
            mediumImage={mapUrls[MEDIUM_IMAGE_SIZE]}
            mediumHeight={MEDIUM_MAP_HEIGHT}
            largeImage={mapUrls[LARGE_IMAGE_SIZE]}
            largeHeight={LARGE_MAP_HEIGHT}
            mapLink={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              locationToMapAddress(marketableLocation)
            )}`}
            label={intl.formatMessage(messages.staticMapLabel)}
          />
        </Container>
      </ContentSection>
    );
  }
);
