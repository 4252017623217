import React from 'react';
import { object, bool } from 'prop-types';
import { FormattedMessage } from '@kyruus/intl';
import Video from '../../Video';
import { Container, Heading, AboutSummary, StyledVideo } from './styles';
import LoadingSkeleton from './loading-skeleton';
import { messages } from './messages';

export default function AboutLocation({ loading, locationData = {} }) {
  const { location_description = '', name = '', media = [] } = locationData;
  const hasVideo = media.find((item) => item.media_type === 'video');
  if (loading) {
    return <LoadingSkeleton />;
  }
  return (
    <React.Fragment>
      {locationData && location_description ? (
        <Container data-testid="AboutLocationContainer">
          <Heading>
            <FormattedMessage {...messages.about} /> {name}
          </Heading>
          <AboutSummary>{location_description}</AboutSummary>
          {hasVideo ? (
            <StyledVideo>
              <Video
                accessibilityText={hasVideo.accessibility_text}
                className="mloc-video"
                url={hasVideo.url}
                width="90%"
                height="400"
                testId="mloc-video"
              />
            </StyledVideo>
          ) : null}
        </Container>
      ) : null}
    </React.Fragment>
  );
}

const propTypes = {
  loading: bool.isRequired,
  locationData: object
};

AboutLocation.propTypes = propTypes;
