import React, { ComponentProps } from 'react';
import { ShimmeringLoaderBar } from '@kyruus/loaders-and-spinners';

import { MarketableLocation } from 'Src/types';
import { getDisplayAddress } from 'Src/utils/location';
import { LoadingContainer } from '../styles';
import { OpenChip } from './OpenChip';
import {
  LocationName,
  LocationAddress,
  LocationChipsContainer,
  LocationChip
} from './styles';

interface BaseDetailsProps {
  loading: boolean;
  marketableLocation?: MarketableLocation;
}

type DetailsProps = BaseDetailsProps & ComponentProps<'div'>;

export const Details = ({
  loading,
  marketableLocation,
  className
}: DetailsProps) => {
  if (loading) {
    return (
      <LoadingContainer data-testid="details-loading" role="presentation">
        <ShimmeringLoaderBar width={50} />
        <ShimmeringLoaderBar width={30} height={30} />
        <ShimmeringLoaderBar width={30} height={30} />
      </LoadingContainer>
    );
  }

  if (!marketableLocation) {
    return null;
  }

  const { name, address, location_type, hours_of_operation } =
    marketableLocation;

  return (
    <section className={className} aria-labelledby="location-title">
      <LocationName id="location-title">{name}</LocationName>
      <LocationAddress>{getDisplayAddress(address)}</LocationAddress>
      <LocationChipsContainer>
        {hours_of_operation && hours_of_operation.periods?.length > 0 && (
          <OpenChip hoursOfOperations={hours_of_operation} />
        )}
        {location_type?.name != null && (
          <LocationChip label={location_type.name} size="small" />
        )}
      </LocationChipsContainer>
    </section>
  );
};
