import { fromTheme } from '@kyruus/ui-theme';
import styled from '@emotion/styled';

import LocationVideo from '../../Video';

export const Lists = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${fromTheme('spacing_xlarge')};
  margin-bottom: ${fromTheme('spacing_large')};
`;

export const Description = styled.p`
  white-space: pre-wrap;
`;

export const Video = styled(LocationVideo)`
  margin: ${fromTheme('spacing_medium')} 0;
  max-width: 560px;
`;

export const ListSectionHeader = styled.h3`
  color: ${fromTheme('color_text')};
  display: block;
  font-weight: bold;
  margin-bottom: ${fromTheme('spacing_medium')};
`;

const SummaryBlockList = styled.ul`
  list-style: none;
  padding: 0;
`;
const SummaryBlockListItem = styled.li`
  line-height: ${fromTheme('font_line_height')};
  display: flex;
  white-space: no-wrap;
  padding: ${fromTheme('spacing_xsmall')} 0;
  justify-content: start;
  gap: ${fromTheme('spacing_small')};

  label {
    line-height: ${fromTheme('font_line_height')};
    display: inline-block;
    padding: 0;
    text-indent: initial;
    width: 80px;
  }

  span {
    align-content: center;
  }
`;

export const HourList = styled(SummaryBlockList)``;
export const HourItem = styled(SummaryBlockListItem)<{ isToday: boolean }>`
  font-weight: ${({ isToday }) =>
    isToday ? fromTheme('font_weight_bolder') : fromTheme('font_weight')};
`;

export const ContactList = styled(SummaryBlockList)``;
export const ContactItem = styled(SummaryBlockListItem)``;
export const AccessibilityList = styled(SummaryBlockList)`
  list-style: disc;
  margin-left: ${fromTheme('spacing_medium')};
`;
export const AccessibilityListItem = styled(SummaryBlockListItem)`
  display: list-item;
`;
