import React, { ComponentProps } from 'react';
import { FormattedMessage } from '@kyruus/intl';

import { MarketableLocationHoursOfOperation } from 'Src/types';
import messages from '../messages';
import { getFormattedHours } from '../utils';
import { HourList, HourItem } from './styles';

interface BaseHoursProps {
  hours: MarketableLocationHoursOfOperation;
}

type HoursProps = BaseHoursProps & ComponentProps<'ul'>;

export const Hours = ({ hours, ...props }: HoursProps) => (
  <HourList {...props}>
    {getFormattedHours(hours).map(
      ({ day, openTime, closeTime, open, today }) => (
        <HourItem key={day} isToday={today}>
          <label>{day}</label>
          {open ? (
            <FormattedMessage
              {...messages.periodOpen}
              values={{
                openTime,
                closeTime
              }}
            />
          ) : (
            <FormattedMessage {...messages.periodClosed} />
          )}
        </HourItem>
      )
    )}
  </HourList>
);
