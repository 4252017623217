import { createMachine, assign } from 'xstate';

import { EVENTS, LocationContext, LocationEvent, STATES } from './types';
import { assertEventType } from '../utils';

export const createLocationV9Machine = (initialContext: LocationContext) =>
  createMachine<LocationContext, LocationEvent>(
    {
      id: 'location-v9',
      initial: STATES.initializing,
      context: {
        locationId: initialContext.locationId,
        location: initialContext.location,
        fetchLocation: initialContext.fetchLocation
      },
      states: {
        [STATES.initializing]: {
          always: [
            { target: STATES.idle, cond: 'hasLocationInContext' },
            { target: STATES.loading, cond: 'hasLocationIdInContext' },
            { target: STATES.error }
          ]
        },
        [STATES.loading]: {
          invoke: {
            id: 'fetchLocationThroughStore',
            src: 'fetchLocationThroughStore',
            onDone: {
              target: STATES.idle,
              actions: 'onFetchLocationSuccess'
            },
            onError: {
              target: STATES.error,
              actions: 'onFetchLocationError'
            }
          }
        },
        [STATES.idle]: {},
        [STATES.error]: {}
      }
    },
    {
      actions: {
        onFetchLocationSuccess: assign({
          // the location that comes back from location service is wrapped in _result
          location: (_ctx, event) => {
            assertEventType(event, EVENTS.fetchLocationThroughStoreSuccess);
            return event.data._result;
          }
        }),
        onFetchLocationError: assign({
          error: (_ctx, event) => {
            assertEventType(event, EVENTS.fetchLocationThroughStoreError);
            return event;
          }
        })
      },
      guards: {
        hasLocationIdInContext: (ctx) => Boolean(ctx.locationId),
        hasLocationInContext: (ctx) => Boolean(ctx.location)
      },
      services: {
        fetchLocationThroughStore: async (ctx) => {
          const { locationId, fetchLocation } = ctx;

          // this is the same fetch function that redux uses, modified slightly
          // so it returns the data rather than just call dispatch. it returns
          // a Promise, so works as-is as a service in xstate
          return fetchLocation(locationId);
        }
      }
    }
  );
