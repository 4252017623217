import styled from '@emotion/styled';

import { fromTheme } from '@kyruus/ui-theme';
import Chip from '@kyruus/chip';

export const LocationName = styled('h1')`
  font-size: ${fromTheme('font_size_heading_1')};
  margin: ${fromTheme('spacing_medium')} 0 ${fromTheme('spacing_small')};
`;

export const LocationAddress = styled.div`
  margin-top: ${fromTheme('spacing_medium')};
  font-size: ${fromTheme('font_size_heading_5')};
`;

export const LocationChipsContainer = styled('div')`
  margin-top: ${fromTheme('spacing_medium')};
  display: flex;
  flex-wrap: wrap;
  gap: ${fromTheme('spacing_xsmall')};
`;

export const LocationChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'color'
})`
  && {
    background-color: ${fromTheme('color_background_secondary')};
    border-color: ${fromTheme('color_background_secondary')};
    border-radius: ${fromTheme('border_radius_badge_chip')};
    border-width: ${fromTheme('border_width')};
    border-style: ${fromTheme('border_style')};
    font-family: ${fromTheme('font_face')};
    font-size: ${fromTheme('font_size')};
    font-weight: ${fromTheme('font_weight')};
    margin-right: ${fromTheme('spacing_small')};
    line-height: ${fromTheme('font_line_height')};
  }
`;
