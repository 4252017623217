import { createMachine, assign } from 'xstate';
import querystring from 'querystring';

import { STATES, EVENTS, MapContext, MapEvent } from './types';
import { assertEventType } from 'Src/machines/utils';
import {
  LARGE_IMAGE_SIZE,
  MAP_PROVIDERS,
  MEDIUM_IMAGE_SIZE,
  SMALL_IMAGE_SIZE
} from '../constants';

export const createMapMachine = (initialContext: MapContext) =>
  createMachine<MapContext, MapEvent>(
    {
      id: 'location-map',
      initial: STATES.initializing,
      context: initialContext,
      states: {
        [STATES.initializing]: {
          always: [
            { target: STATES.loading, cond: 'isEnabled' },
            { target: STATES.error }
          ]
        },
        [STATES.loading]: {
          invoke: {
            id: 'fetchMap',
            src: 'fetchMap',
            onDone: {
              target: STATES.idle,
              actions: 'onFetchSuccess'
            },
            onError: {
              target: STATES.error,
              actions: 'onFetchError'
            }
          }
        },
        [STATES.idle]: {},
        [STATES.error]: {}
      }
    },
    {
      actions: {
        onFetchSuccess: assign({
          mapUrls: (_ctx, event) => {
            assertEventType(event, EVENTS.fetchMapSuccess);
            return event.data;
          }
        }),
        onFetchError: assign({
          error: (_ctx, event) => {
            assertEventType(event, EVENTS.fetchMapError);
            return event;
          }
        })
      },
      guards: {
        isEnabled: (ctx) => ctx.provider !== MAP_PROVIDERS.AWS
      },
      services: {
        fetchMap: async (ctx) => {
          const { locations, zoom } = ctx;
          const devicePixelRatio =
            typeof window != 'undefined' && window.devicePixelRatio;
          const scale = devicePixelRatio || 1;

          const params = {
            ids: [...new Set(locations)].join(','),
            sizes: [
              ...new Set([
                SMALL_IMAGE_SIZE,
                MEDIUM_IMAGE_SIZE,
                LARGE_IMAGE_SIZE
              ])
            ].join(','),
            ...(scale && { scale }),
            ...(zoom && { zoom }),
            format: 'png'
          };

          const response = await fetch(
            '/google-maps/static-map/mlocs?' + querystring.stringify(params),
            { credentials: 'same-origin' }
          );

          if (!response.ok) {
            throw new Error(response.statusText);
          }

          const jsonData = await response.json();
          return jsonData.data;
        }
      }
    }
  );
