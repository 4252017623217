import { MarketableLocation } from 'Src/types';

/**
 * Return a string representation of a map address for a given location.
 * For example : "55 Milk St, Boston, Massachusetts, 02130"
 * Any missing address components will be omitted
 * @param {Object} location mloc object
 */
export const locationToMapAddress = (location: MarketableLocation) => {
  const address = (location || {}).address || {};
  return [
    location.name,
    address.street1,
    address.city,
    address.state,
    address.zip
  ]
    .filter(Boolean)
    .join(', ');
};
