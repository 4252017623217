import React from 'react';
import { useMachine } from '@xstate/react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PMCConfig } from '@kyruus/types';
import { injectIntl } from 'react-intl';
import { WrappedComponentProps } from '@kyruus/intl';

import { MarketableLocation, Log, SearchQuery, AppSettings } from 'Src/types';
import { createLocationV9Machine, STATES } from 'Src/machines/location-v9';
import { LocationData } from 'Src/machines/location-v9/types';
import PoweredByLogo from 'Src/shared/powered-by-logo';
import Error from 'Src/error';

import { Meta } from './Meta';
import { Summary } from './Summary';
import { About } from './About';
import { Map } from './Map';
import { LocationPage, MainContent } from './styles';
import { List } from './List';
import { TITLE_MESSAGES, SEARCH_TITLE_MESSAGES } from './constants';
import { Providers } from './Providers';

interface BaseLocationProps extends WrappedComponentProps {
  appSettings: AppSettings;
  config: PMCConfig;
  currentQuery: SearchQuery;
  fetchLocation: () => Promise<LocationData>;
  locationId: string;
  locationObject: MarketableLocation;
  log: Log;
}

type LocationV9Props = BaseLocationProps & RouteComponentProps;

export const LocationV9 = injectIntl(
  withRouter(
    ({
      appSettings,
      config,
      currentQuery,
      fetchLocation,
      history,
      intl,
      locationId,
      locationObject,
      log
    }: LocationV9Props) => {
      const [state] = useMachine(
        createLocationV9Machine({
          locationId,
          location: locationObject,
          fetchLocation
        })
      );

      const { location: marketableLocation } = state.context;

      const loading = state.matches(STATES.loading);

      if (state.matches(STATES.error)) {
        return <Error config={config} />;
      }

      return (
        <React.Fragment>
          {!loading && marketableLocation && (
            <Meta config={config} marketableLocation={marketableLocation} />
          )}
          <LocationPage
            id={locationId}
            itemScope={true}
            itemType="http://schema.org/Place"
            aria-live="polite"
            data-testid="LocationSummary"
          >
            <Summary
              currentQuery={currentQuery}
              history={history}
              loading={loading}
              log={log}
              marketableLocation={marketableLocation}
            />

            {!loading && marketableLocation && (
              <MainContent>
                <About marketableLocation={marketableLocation} log={log} />
                <Map
                  marketableLocation={marketableLocation}
                  appSettings={appSettings}
                  config={config}
                />

                {marketableLocation.insurances.length > 0 && (
                  <List
                    data={marketableLocation.insurances}
                    log={log}
                    id="insurances"
                    searchInputTitle={intl.formatMessage(
                      SEARCH_TITLE_MESSAGES.INSURANCES
                    )}
                    title={intl.formatMessage(TITLE_MESSAGES.INSURANCES, {
                      count: marketableLocation.insurances.length
                    })}
                  />
                )}
                {marketableLocation.services.length > 0 && (
                  <List
                    data={marketableLocation.services}
                    log={log}
                    id="services"
                    searchInputTitle={intl.formatMessage(
                      SEARCH_TITLE_MESSAGES.SERVICES
                    )}
                    title={intl.formatMessage(TITLE_MESSAGES.SERVICES)}
                  />
                )}
                {marketableLocation.specialties.length > 0 && (
                  <List
                    data={marketableLocation.specialties}
                    log={log}
                    id="specialties"
                    searchInputTitle={intl.formatMessage(
                      SEARCH_TITLE_MESSAGES.SPECIALTIES
                    )}
                    title={intl.formatMessage(TITLE_MESSAGES.SPECIALTIES)}
                  />
                )}
                <Providers log={log} config={config} />
              </MainContent>
            )}
          </LocationPage>
          <PoweredByLogo config={config} className="visible-print" />
        </React.Fragment>
      );
    }
  )
);
