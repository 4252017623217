import React, { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from '@kyruus/intl';
import KyruusLink from '@kyruus/link';

import { Log, MarketableLocation, SearchQuery } from 'Src/types';
import { getBackToSearchUrl } from 'Src/utils/location';
import messages from '../messages';
import { SummaryContainer, SummaryImage } from './styles';
import { Details } from './Details';

interface SummaryProps {
  currentQuery: SearchQuery;
  history: any;
  loading: boolean;
  log: Log;
  marketableLocation?: MarketableLocation;
}

export const Summary = ({
  currentQuery,
  history,
  loading,
  log,
  marketableLocation
}: SummaryProps) => {
  const backToSearch = getBackToSearchUrl(currentQuery) || '/search';

  const handleBackToSearchClick = (e: MouseEvent<HTMLAnchorElement>) => {
    // TODO figure out why the router link isn't doing a SPA/client-side transition like it should?
    e.preventDefault();
    log('user_action.location_page.back_to_search');
    history.push(backToSearch);
  };

  return (
    <SummaryContainer>
      <FormattedMessage {...messages.backLink}>
        {(message) => (
          <Link
            data-testid="BackToSearch"
            to={backToSearch}
            onClick={handleBackToSearchClick}
            component={KyruusLink}
          >
            {message}
          </Link>
        )}
      </FormattedMessage>
      <SummaryImage loading={loading} marketableLocation={marketableLocation} />
      <Details loading={loading} marketableLocation={marketableLocation} />
    </SummaryContainer>
  );
};
