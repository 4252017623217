import React from 'react';
import { injectIntl, WrappedComponentProps } from '@kyruus/intl';

import { MarketableLocationHoursOfOperation } from 'Src/types';
import { getTodaysHours } from '../../utils';
import messages from '../../messages';
import { LocationChip } from './styles';

interface OpenChipProps extends WrappedComponentProps {
  hoursOfOperations: MarketableLocationHoursOfOperation;
}

export const OpenChip = injectIntl(
  ({ hoursOfOperations, intl }: OpenChipProps) => {
    const todaysHours = getTodaysHours(hoursOfOperations);

    if (!todaysHours || !todaysHours.open) {
      return null;
    }

    return (
      <LocationChip
        color="color_background_success"
        label={intl.formatMessage(messages.openTime, {
          closeTime: todaysHours.closeTime
        })}
        size="small"
      />
    );
  }
);
