import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const LocationPage = styled.div`
  font-size: ${fromTheme('font_size')};
  margin: 0 auto;
  padding: 0 ${fromTheme('spacing_large')} ${fromTheme('spacing_medium')}
    ${fromTheme('spacing_large')};
  background-color: ${fromTheme('color_background')};
  font-family: ${fromTheme('font_face')};
  max-width: 1440px;

  * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  gap: ${fromTheme('spacing_large')};
`;

export const MainContent = styled('div')``;

export const SectionHeader = styled('h2')`
  font-size: ${fromTheme('font_size_heading_4')};
  margin-bottom: ${fromTheme('spacing_medium')};
`;

export const ContentSection = styled.section`
  margin: ${fromTheme('spacing_large')} 0;
  padding-top: ${fromTheme('spacing_large')};
  border-top: ${fromTheme('border_width')} ${fromTheme('border_style')}
    ${fromTheme('color_border')};

  &:first-of-type {
    margin-top: 0;
  }
`;

export const ContentList = styled.div`
  white-space: nowrap;

  > div {
    border: none;
    padding: 0;
  }
  h2 {
    font-size: ${fromTheme('font_size_heading_5')};
    font-weight: ${fromTheme('font_weight_bolder')};
    color: ${fromTheme('color_text')};
  }

  && li {
    list-style-type: initial;
    color: ${fromTheme('color_text')};
    margin-bottom: ${fromTheme('spacing_xsmall')};
  }
`;
