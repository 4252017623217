import messages from './messages';

export const INSURANCES = 'insurances';
export const SERVICES = 'services';
export const SPECIALTIES = 'specialties';
export const TITLE_MESSAGES = {
  INSURANCES: messages.infoListBoxInsurances,
  SERVICES: messages.infoListBoxServices,
  SPECIALTIES: messages.infoListBoxSpecialties
};
export const SEARCH_TITLE_MESSAGES = {
  INSURANCES: messages.infoListBoxInsurancesSearch,
  SERVICES: messages.infoListBoxServicesSearch,
  SPECIALTIES: messages.infoListBoxSpecialtiesSearch
};
export const COLUMN_ROW_BREAK = 5;
export const NUMBER_OF_COLUMNS = 3;
export const TRUNCATE = 15;
export const SHOW_SEARCH_ITEMS_THRESHOLD = 20;
