import { EventObject } from 'xstate';

/**
 * Function that asserts that a given event matches one of the included types.
 * Throws an error if the event type does not match.
 * @param {TE} event an event or object returned from an event
 * @param {...TType} eventTypes list of acceptable event types
 *
 */
export function assertEventType<
  TE extends EventObject,
  TType extends TE['type']
>(event: TE, ...eventTypes: TType[]): asserts event is TE & { type: TType } {
  if (!eventTypes.includes(event.type as TType)) {
    throw new Error(
      `Invalid event: expected one of ["${eventTypes.join('", "')}"], got "${
        event.type
      }"`
    );
  }
}
