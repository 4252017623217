import { fromTheme } from '@kyruus/ui-theme';
import styled from '@emotion/styled';

export const HeroBtn = styled('div')`
  margin-bottom: ${fromTheme('spacing_small')};
`;

export const HeroImage = styled('div')<{ imageUrl: string }>`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('${({ imageUrl }) => imageUrl}');

  @media (min-width: ${fromTheme('screen_medium')}) {
    background-image: url('${({ imageUrl }) => imageUrl}');
  }
`;
