import React, { ComponentProps } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from '@kyruus/intl';

import { ContentSection, SectionHeader } from '../styles';
import messages from '../messages';
import { Log } from 'Src/types';
import { PMCConfig } from '@kyruus/types';
import { getDefaultCustomerSort } from 'Src/utils/search-common';
import { getProvidersAtLocationUrl } from 'Src/utils/url';
import { FindProvidersButtonLink } from './FindProvidersButtonLink';

interface BaseProvidersProps {
  log: Log;
  config: PMCConfig;
}

type ProvidersProps = BaseProvidersProps & ComponentProps<'section'>;

export const Providers = ({ config, log, ...props }: ProvidersProps) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const defaultSort = getDefaultCustomerSort(config);
  const findProvidersLink = getProvidersAtLocationUrl(id, history, defaultSort);

  const handleFindProvidersClick = () => {
    log('user_action.location_page.find_providers');
  };

  return (
    <ContentSection
      data-testid="location-providers"
      aria-labelledby="location-providers-title"
      {...props}
    >
      <FormattedMessage {...messages.providersTitle}>
        {(text) => (
          <SectionHeader id="location-providers-title">{text}</SectionHeader>
        )}
      </FormattedMessage>

      <FormattedMessage {...messages.findProvidersAtLocationButtonLabel}>
        {(message) => (
          <Link
            to={findProvidersLink}
            onClick={handleFindProvidersClick}
            component={FindProvidersButtonLink}
          >
            {message}
          </Link>
        )}
      </FormattedMessage>
    </ContentSection>
  );
};
