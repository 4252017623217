import React, { ComponentProps } from 'react';
import { FormattedMessage } from '@kyruus/intl';

import { Log, MarketableLocation } from 'Src/types';
import messages from '../messages';
import { SectionHeader, ContentSection } from '../styles';
import {
  Description,
  Video,
  Lists,
  ListSectionHeader,
  AccessibilityList,
  AccessibilityListItem
} from './styles';
import { Hours } from './Hours';
import { Contacts } from './Contacts';

interface BaseAboutProps {
  marketableLocation: MarketableLocation;
  log: Log;
}

type AboutProps = BaseAboutProps & ComponentProps<'section'>;

export const About = ({ marketableLocation, log, ...props }: AboutProps) => {
  const {
    location_description,
    hours_of_operation,
    contacts,
    urls,
    media,
    accessibility
  } = marketableLocation;
  const videoMedia = media.find((item) => item.media_type === 'video');

  const showContacts = contacts.length > 0;
  const showHours =
    !!hours_of_operation && hours_of_operation.periods.length > 0;
  const showAccessibility = !!accessibility && accessibility.length > 0;
  const showDescription = !!location_description;
  const showVideo = !!videoMedia;
  const showLists = showContacts || showHours || showAccessibility;

  if (
    !showContacts &&
    !showHours &&
    !showAccessibility &&
    !showDescription &&
    !showVideo
  ) {
    return null;
  }

  return (
    <ContentSection
      data-testid="location-about"
      aria-labelledby="location-about-title"
      {...props}
    >
      <FormattedMessage {...messages.aboutTitle}>
        {(text) => (
          <SectionHeader id="location-about-title">{text}</SectionHeader>
        )}
      </FormattedMessage>

      {showLists && (
        <Lists>
          {showContacts && (
            <div>
              <FormattedMessage {...messages.contactsTitle}>
                {(text) => (
                  <ListSectionHeader id="location-about-contacts-title">
                    {text}
                  </ListSectionHeader>
                )}
              </FormattedMessage>
              <Contacts
                contacts={contacts}
                urls={urls}
                log={log}
                aria-labelledby="location-about-contacts-title"
              />
            </div>
          )}

          {showHours && (
            <div>
              <FormattedMessage {...messages.hoursTitle}>
                {(text) => (
                  <ListSectionHeader id="location-about-hours-title">
                    {text}
                  </ListSectionHeader>
                )}
              </FormattedMessage>
              <Hours
                hours={hours_of_operation}
                aria-labelledby="location-about-hours-title"
              />
            </div>
          )}

          {showAccessibility && (
            <div>
              <FormattedMessage {...messages.accessibilityTitle}>
                {(text) => (
                  <ListSectionHeader id="location-about-accessibility-title">
                    {text}
                  </ListSectionHeader>
                )}
              </FormattedMessage>
              <AccessibilityList aria-labelledby="location-about-accessibility-title">
                {accessibility.map(({ name }) => (
                  <AccessibilityListItem key={name}>
                    {name}
                  </AccessibilityListItem>
                ))}
              </AccessibilityList>
            </div>
          )}
        </Lists>
      )}

      {showDescription && <Description>{location_description}</Description>}

      {showVideo && (
        <Video
          accessibilityText={videoMedia.accessibility_text}
          url={videoMedia.url}
          width="100%"
          height="315"
          testId="mloc-video"
        />
      )}
    </ContentSection>
  );
};
