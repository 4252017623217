import React, { ComponentProps } from 'react';
import { FormattedMessage } from '@kyruus/intl';
import { ProviderMatchContact } from '@kyruus/types';
import Link from '@kyruus/link';

import { MarketableLocationUrl, Log } from 'Src/types';
import { getEmail, getFax, getPhone, getWebsiteUrl } from 'Src/utils/location';
import messages from '../messages';
import { ContactItem, ContactList } from './styles';

interface BaseContactsProps {
  contacts: ProviderMatchContact[];
  urls: MarketableLocationUrl[];
  log: Log;
}

type ContactsProps = BaseContactsProps & ComponentProps<'ul'>;

export const Contacts = ({ contacts, urls, log, ...props }: ContactsProps) => {
  const websiteUrl = getWebsiteUrl(urls);
  const phone = getPhone(contacts);
  const email = getEmail(contacts);
  const fax = getFax(contacts);

  if (!websiteUrl && !phone && !email && !fax) {
    return null;
  }

  return (
    <ContactList {...props}>
      {phone && (
        <ContactItem>
          <FormattedMessage {...messages.labelPhone} tagName="label" />
          <span>
            <Link
              data-testid="PhoneLink"
              href={`tel:${phone}`}
              onClick={() => {
                log('user_action.location_page.phone_link_click');
              }}
            >
              {phone}
            </Link>
          </span>
        </ContactItem>
      )}
      {websiteUrl?.href && (
        <ContactItem>
          <FormattedMessage {...messages.labelWebsite} tagName="label" />
          <span>
            <Link
              data-testid="WebsiteLink"
              rel="noopener noreferrer"
              target="_blank"
              href={websiteUrl.href}
              onClick={() =>
                log('user_action.location_page.website_link_click')
              }
            >
              {websiteUrl.display}
            </Link>
          </span>
        </ContactItem>
      )}
      {email && (
        <ContactItem>
          <FormattedMessage {...messages.labelEmail} tagName="label" />
          <span>
            <Link
              data-testid="EmailLink"
              href={`mailto:${email}`}
              onClick={() => {
                log('user_action.location_page.email_link_click');
              }}
            >
              {email}
            </Link>
          </span>
        </ContactItem>
      )}
      {fax && (
        <ContactItem>
          <FormattedMessage {...messages.labelFax} tagName="label" />
          <span>{fax}</span>
        </ContactItem>
      )}
    </ContactList>
  );
};
