import React from 'react';

import { ButtonLink, ButtonLinkProps } from '@kyruus/button';

export const FindProvidersButtonLink = ({
  children,
  ...props
}: ButtonLinkProps) => (
  <ButtonLink {...props} mode="ghost" data-testid="FindProvidersBtn">
    {children}
  </ButtonLink>
);
