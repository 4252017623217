import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

import { Image } from './Image';

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${fromTheme('spacing_small')};
`;

export const SummaryImage = styled(Image)`
  width: 100%;
  height: 180px;

  @media (min-width: ${fromTheme('screen_medium')}) {
    height: 277px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${fromTheme('spacing_medium')};
`;

export const ImageLoadingContainer = styled(LoadingContainer)`
  > div {
    height: 180px;

    @media (min-width: ${fromTheme('screen_medium')}) {
      height: 277px;
    }
  }
`;
