import moment from 'moment';

import {
  MarketableLocationHoursOfOperation,
  MarketableLocationHoursPeriod
} from 'Src/types';

/**
 *
 * @param format e.g. dddd | ddd
 * @returns
 */
export const getWeekdays = (format: string) =>
  new Array(7).fill(null).map((_, i) => {
    return moment(i, 'e')
      .startOf('week')
      .isoWeekday(i + 1)
      .format(format);
  });

/**
 * Get a list of formatted hours with open and close times
 * @param {MarketableLocationHoursOfOperation} hours
 */
export const getFormattedHours = (
  hours: MarketableLocationHoursOfOperation
) => {
  const weekdays = getWeekdays('dddd');
  const weekdaysShort = getWeekdays('ddd');
  const currentDay = moment().format('dddd');

  const periodsByDay = hours.periods.reduce<
    Record<string, MarketableLocationHoursPeriod>
  >((acc, period) => {
    acc[period.openDay] = period;
    return acc;
  }, {});

  return weekdays.map((day, index) => {
    const period: MarketableLocationHoursPeriod | undefined = periodsByDay[day];

    return {
      day,
      shortDay: weekdaysShort[index],
      openTime: period?.openTime
        ? moment(period.openTime, 'HH:mm').format('h:mm A')
        : undefined,
      closeTime: period?.closeTime
        ? moment(period.closeTime, 'HH:mm').format('h:mm A')
        : undefined,
      open: period?.openTime,
      today: day === currentDay
    };
  });
};

/**
 * Get the open/close time for today
 * @param {MarketableLocationHoursOfOperation} hours
 */
export const getTodaysHours = (hours: MarketableLocationHoursOfOperation) => {
  const currentDay = moment().format('dddd');

  const todaysPeriod = hours.periods.find(
    (period) => period.openDay === currentDay
  );

  if (!todaysPeriod) return;

  const format = 'HH:mm';
  const startTime = moment(todaysPeriod.openTime, format);
  const endTime = moment(todaysPeriod.closeTime, format);
  const open = moment(moment(), format).isBetween(startTime, endTime);

  return {
    currentDay,
    openTime: moment(todaysPeriod.openTime, 'HH:mm').format('h:mm A'),
    closeTime: moment(todaysPeriod.closeTime, 'HH:mm').format('h:mm A'),
    open
  };
};
