import { defineMessages } from '@kyruus/intl';

export default defineMessages({
  title: {
    id: 'location.title',
    description: 'Page title for the location',
    defaultMessage: '{name} - {city} - {state} - {locationType}'
  },
  description: {
    id: 'location.description',
    description: 'Page description for the location',
    defaultMessage:
      '{name}. {address} - {locationType} Services: {serviceNames} Specialties: {specialtyNames}'
  },
  aboutTitle: {
    id: 'location.about.title',
    description: 'About section title',
    defaultMessage: 'About'
  },
  accessibilityTitle: {
    id: 'location.accessibility.title',
    description: 'Accessibility section tiel',
    defaultMessage: 'Location Details'
  },
  contactsTitle: {
    id: 'location.contacts.title',
    description: 'Contacts section title',
    defaultMessage: 'Contact'
  },
  hoursTitle: {
    id: 'location.hours.title',
    description: 'Hours section title',
    defaultMessage: 'Hours'
  },
  periodClosed: {
    id: 'location.hours.periodClosed',
    description: 'Label displayed when a location is closed for a given day',
    defaultMessage: 'Closed'
  },
  periodOpen: {
    id: 'location.hours.periodOpen',
    description: 'Label displayed when a location is open for a given day',
    defaultMessage: '{openTime} - {closeTime}'
  },
  labelPhone: {
    id: 'location.profile.phone',
    description: 'profile label phone',
    defaultMessage: 'Phone'
  },
  labelWebsite: {
    id: 'location.profile.website',
    description: 'profile label website',
    defaultMessage: 'Website'
  },
  labelEmail: {
    id: 'location.profile.email',
    description: 'profile label email',
    defaultMessage: 'Email'
  },
  labelFax: {
    id: 'location.profile.fax',
    description: 'profile label fax',
    defaultMessage: 'Fax'
  },
  backLink: {
    id: 'location.backLink',
    description: 'Back to search link text',
    defaultMessage: 'Back'
  },
  openTime: {
    id: 'location.chip.openTime',
    description: 'Open time for a location',
    defaultMessage: 'Open Now • Closes {closeTime}'
  },
  infoListBoxInsurances: {
    id: 'infoListBox.insurances',
    description: 'InfoListBox insurance title',
    defaultMessage: `Insurance Accepted {count, plural,
      one {(# plan)}
      other {(# plans)}
    }`
  },
  infoListBoxInsurancesSearch: {
    id: 'infoListBox.insurances.search',
    description: 'InfoListBox insurance search title',
    defaultMessage: 'Search plans'
  },
  infoListBoxServices: {
    id: 'infoListBox.services',
    description: 'InfoListBox services title',
    defaultMessage: 'Services'
  },
  infoListBoxServicesSearch: {
    id: 'infoListBox.services.search',
    description: 'InfoListBox services search title',
    defaultMessage: 'Search services'
  },
  infoListBoxSpecialties: {
    id: 'infoListBox.specialties',
    description: 'InfoListBox specialties title',
    defaultMessage: 'Specialties'
  },
  infoListBoxSpecialtiesSearch: {
    id: 'infoListBox.specialties.search',
    description: 'InfoListBox specialties search title',
    defaultMessage: 'Search specialties'
  },
  staticMapLabel: {
    id: 'staticMap.label',
    description: 'aria label for the static map',
    defaultMessage: 'Street Map'
  },
  providersTitle: {
    id: 'location.providers.title',
    description: 'Providers section title',
    defaultMessage: 'Providers who work here'
  },
  findProvidersAtLocationButtonLabel: {
    id: 'location.providers.findButton',
    description: 'Find providers at this location button label',
    defaultMessage: 'Search Providers At This Location'
  }
});
