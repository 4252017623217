import { StateMachine } from 'xstate';
import { MarketableLocation } from 'Src/types';

export const STATES = {
  initializing: '@states/INITIALIZING',
  idle: '@states/IDLE',
  loading: '@states/LOADING',
  error: '@states/ERROR'
} as const;

export const EVENTS = {
  fetchLocationThroughStoreSuccess: 'done.invoke.fetchLocationThroughStore',
  fetchLocationThroughStoreError: 'error.platform.fetchLocationThroughStore'
} as const;

export type LocationData = {
  data: {
    _result: MarketableLocation;
  };
};

export type LocationStateMachine = StateMachine<
  LocationContext,
  LocationStateSchema,
  LocationEvent
>;

export interface LocationContext {
  locationId: string;
  location?: MarketableLocation;
  fetchLocation: (locationId: string) => Promise<LocationData>;
  error?: unknown;
}

export interface LocationStateSchema {
  states: {
    [STATES.initializing]: Record<string, unknown>;
    [STATES.idle]: Record<string, unknown>;
    [STATES.loading]: Record<string, unknown>;
    [STATES.error]: Record<string, unknown>;
  };
}

export type LocationEvent =
  | ({
      type: typeof EVENTS.fetchLocationThroughStoreSuccess;
    } & LocationData)
  | { type: typeof EVENTS.fetchLocationThroughStoreError; error: unknown };
