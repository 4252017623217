import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';
import {
  SMALL_MAP_HEIGHT,
  MEDIUM_MAP_HEIGHT,
  LARGE_MAP_HEIGHT
} from './constants';

export const Container = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: ${fromTheme('spacing_large')};
  height: ${SMALL_MAP_HEIGHT};
  overflow: hidden;

  @media (min-width: ${fromTheme('screen_medium')}) {
    height: ${MEDIUM_MAP_HEIGHT};
  }
  @media (min-width: ${fromTheme('screen_large')}) {
    height: ${LARGE_MAP_HEIGHT};
  }
`;
