import React from 'react';
import Helmet from 'react-helmet';
import { injectIntl, WrappedComponentProps } from '@kyruus/intl';
import { PMCConfig } from '@kyruus/types';

import {
  DEFAULT_SITE_TITLE,
  DEFAULT_LOCALE,
  getConfigProperty
} from 'Common/config';
import { MarketableLocation } from 'Src/types';
import {
  getDisplayAddress,
  buildLocationCanonicalURL,
  getHeroImage
} from 'Src/utils/location';
import messages from './messages';

interface MetaProps extends WrappedComponentProps {
  config: PMCConfig;
  marketableLocation: MarketableLocation;
}

export const Meta = injectIntl(
  ({ config, marketableLocation, intl }: MetaProps) => {
    const {
      name,
      address,
      specialties = [],
      services = [],
      media,
      location_type
    } = marketableLocation;

    const locale = getConfigProperty(config, 'locale', DEFAULT_LOCALE);
    const applicationStringTemplates = getConfigProperty(
      config,
      'application_string_templates'
    );
    const primaryDomain = getConfigProperty(config, 'primary_domain');
    const host = getConfigProperty(config, 'host');

    const specialtyNames = specialties.map(({ name }) => name).join(', ') || '';
    const serviceNames = services.map(({ name }) => name).join(', ') || '';

    const title = intl.formatMessage(messages.title, {
      name,
      city: address.city,
      state: address.state,
      locationType: location_type?.name
    });
    const descriptionMessage = intl.formatMessage(messages.description, {
      name,
      address: getDisplayAddress(address),
      locationType: location_type?.name,
      serviceNames,
      specialtyNames
    });
    const url = buildLocationCanonicalURL(
      primaryDomain || host,
      marketableLocation
    );
    const heroImage = getHeroImage(media);

    const descriptionMeta = {
      name: 'description',
      content: descriptionMessage
    };
    const ogTitle = { property: 'og:title', content: title };
    const ogLocale = {
      property: 'og:locale',
      content: locale
    };
    const ogDescription = {
      property: 'og:description',
      content: descriptionMessage
    };
    const ogUrl = {
      name: 'url',
      property: 'og:url',
      itemprop: 'url',
      content: url
    };
    const ogType = { property: 'og:type', content: 'profile' };
    const ogImage = heroImage
      ? {
          property: 'og:image',
          content: heroImage
        }
      : {};
    const ogSiteName = {
      property: 'og:site_name',
      content:
        applicationStringTemplates['providerlist.pagedescription.basic'] ||
        DEFAULT_SITE_TITLE
    };

    return (
      <Helmet
        title={title}
        link={[{ rel: 'canonical', href: url }]}
        meta={[
          descriptionMeta,
          ogTitle,
          ogLocale,
          ogDescription,
          ogUrl,
          ogType,
          ogImage,
          ogSiteName
        ]}
      />
    );
  }
);
