import React from 'react';
import { ProviderInfoListBox } from '@kyruus/provider-components';

import { Log } from 'Src/types';
import {
  NUMBER_OF_COLUMNS,
  TRUNCATE,
  SHOW_SEARCH_ITEMS_THRESHOLD,
  COLUMN_ROW_BREAK
} from './constants';
import { ContentSection, ContentList } from './styles';

interface ListProps {
  data: { name: string }[];
  id: string;
  log: Log;
  searchInputTitle: string;
  title: string;
}

export const List = ({ data, id, log, searchInputTitle, title }: ListProps) => (
  <ContentSection aria-labelledby={`ProviderInfoListBox-title-${id}`}>
    <ContentList>
      <ProviderInfoListBox
        id={id}
        data-testid={`location-list-${id}`}
        title={title}
        itemType={title}
        searchInputTitle={searchInputTitle}
        items={data}
        showClearSearchIcon={true}
        numColumns={data.length > COLUMN_ROW_BREAK ? NUMBER_OF_COLUMNS : 1}
        log={log}
        truncateAt={TRUNCATE}
        isV9Profile={true}
        showSearch={data.length > SHOW_SEARCH_ITEMS_THRESHOLD}
      />
    </ContentList>
  </ContentSection>
);
