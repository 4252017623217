export const SMALL_IMAGE_SIZE = '350x200';
export const MEDIUM_IMAGE_SIZE = '600x226';
export const LARGE_IMAGE_SIZE = '845x226';

export const SMALL_MAP_HEIGHT = '200px';
export const MEDIUM_MAP_HEIGHT = '416px;';
export const LARGE_MAP_HEIGHT = '416px';

export const MAP_PROVIDERS = {
  AWS: 'aws',
  GOOGLE: 'google'
} as const;
